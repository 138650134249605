<template>
  <div>
    <Form
      :label-width="100"
      style="width: 100%"
      ref="plantForm"
      :model="plantForm"
      :rules="rules"
    >
      <FormItem label="种植场名称" prop="planterName">
        <Input
          clearable
          placeholder="请输入种植场名称"
          style="width: 260px"
          v-model="plantForm.planterName"
        ></Input>
      </FormItem>
      <FormItem label="种植户名称" prop="name">
        <Input
          clearable
          placeholder="请输入种植户名称"
          style="width: 260px"
          v-model="plantForm.name"
        ></Input>
      </FormItem>
      <FormItem label="电话号码" prop="tel">
        <Input
          clearable
          placeholder="请输入电话号码"
          style="width: 260px"
          v-model="plantForm.tel"
        ></Input>
      </FormItem>
      <FormItem label="种植类型" prop="types">
        <Select
          clearable
          placeholder="请选择种植类型"
          style="width: 260px"
          v-model="plantForm.types"
        >
          <Option
            v-for="(item, index) in catList"
            :key="index"
            :value="item.id + '/' + item.name"
            >{{ item.name }}</Option
          >
        </Select>
      </FormItem>
      <FormItem label="种植地址" prop="plantingAddress">
        <Input
          clearable
          placeholder="请输入种植地址"
          style="width: 260px"
          v-model="plantForm.plantingAddress"
        ></Input>
      </FormItem>
      <div style="width: 100%; text-align: center">
        <Button
          type="primary"
          style="width: 260px; margin-bottom: 20px"
          @click="submit"
        >
          确认注册并登录
        </Button>
      </div>
    </Form>
  </div>
</template>

<script>
import { V2 } from "@/common/http/api";
import axios from "axios";
import CU from "@/common/util/index";
export default {
  name: "",
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      plantForm: {
        //种植场名称
        planterName: "",
        //种植户名字
        name: "",
        //电话
        tel: "",
        types: "",
        //种植品种类型
        type: "",
        //种植品种类型名称
        typeName: "",
        //种植场地址
        plantingAddress: "",
      },
      rules: {
        name: [{ required: true, message: "请输入种植户名称" }],
        planterName: [{ required: true, message: "请输入种植场名称" }],
        types: [{ required: true, message: "请选择种植类型" }],
        plantingAddress: [{ required: true, message: "请输入种植地址" }],
        tel: [
          { required: true, message: "请输入电话号码" },
          {
            validator(rule, value, callback) {
              if (CU.validatePhone(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
      },
      catList: [],
    };
  },
  methods: {
    //种植类型
    getCat() {
      axios({
        method: "post",
        url: V2 + this.$api.REGISTER.PRO,
        data: {
          type: 1,
        },
      }).then((res) => {
        this.catList = res.data.data.list;
      });
    },
    submit() {
      this.$refs.plantForm.validate().then((res) => {
        if (!res) return;
        axios({
          method: "post",
          url: V2 + this.$api.REGISTER.CHECKS_CODE,
          data: {
            phone: this.form.phone,
            code: this.form.code,
          },
        }).then((res) => {
          if (res.data.data) {
            let params = { ...this.plantForm, ...this.form };
            params.type = this.plantForm.types.split("/")[0];
            params.typeName = this.plantForm.types.split("/")[1];
            axios({
              method: "post",
              url: V2 + this.$api.REGISTER.REGIS,
              data: params,
            }).then(() => {
              this.$Message.success("注册成功！请返回登录界面登录！");
            });
          } else {
            this.$Message.error("验证码输入错误！");
          }
        });
      });
    },
  },
  mounted() {
    // console.log(this.form);
    this.getCat();
  },
};
</script>

<style lang="less" scoped>
</style>